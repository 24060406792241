.App {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  padding: 6rem;
  min-height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

.logged-in-home-paper {
  width: 60%
}

.generate-rate-paper {
  width: 70%;
}

.generate-rate-result-paper {
  width: 90%;
}

@media only screen and (max-width: 600px) {
  .logged-in-home-paper, .generate-rate-result-paper {
    width: 100%
  }
  .generate-rate-paper {
    width: 100%;
  }

  .main {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media only screen and (min-width: 600px) and (max-width: 780px) {
  .logged-in-home-paper, .generate-rate-result-paper {
    width: 100%
  }
  .generate-rate-paper {
    width: 100%;
  }
}
